'use client'; // This is a client component

import { usePathname } from 'next/navigation';
import Footer from './Footer'; // existing Footer component

export default function FooterVisibility() {
  const pathname = usePathname();

  // Do not show footer on the "/signin" page
  const showFooter = pathname !== '/signin';

  return (
    <>
      {showFooter && <Footer />}
    </>
  );
}
