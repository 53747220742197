import(/* webpackMode: "eager" */ "/home/pt/code/kibo-scarlett-dev/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/pt/code/kibo-scarlett-dev/app/PTUI/FooterVisibility.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/home/pt/code/kibo-scarlett-dev/components/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/pt/code/kibo-scarlett-dev/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/home/pt/code/kibo-scarlett-dev/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/pt/code/kibo-scarlett-dev/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Raleway\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-raleway\"}],\"variableName\":\"raleway\"}");
